<template>
  <div>
    <div>
      <ValidationObserver ref="formCreateAlarm">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model.trim="unit_info.name" type="text" field_name="Verksamhetsnamn" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model.trim="unit_info.company_number" type="text" field_name="Org.nummer" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <div class="flex flex-wrap">
              <div v-if="!new RegExp('(SRT334)|(SRT330)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(device_type)" class="w-full md:w-1/3">
                <BaseSwitch v-model="gps_active" field_name="SecurTrack" />
              </div>
              <div v-if="!new RegExp('(SRT334)|(SRT330)|(TWIG Beacon)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(device_type)" class="w-full md:w-1/3">
                <BaseSwitch v-model="gps_log_active" field_name="SecurTool" />
              </div>
            </div>
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>

        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2">
            <BaseSelect v-model="device_type" :options="devices" field_name="Enhetstyp" rules="required" @change="filterOrders()" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseSelect v-model="order_id" :options="orders_filtered" nameSelector="modelnumber" optionSelector="_id" field_name="Inleveransdokument" @change="populateFieldFromOrder()" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(TWIG Beacon)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
            <BaseInput v-model.trim="imei_number" type="text" field_name="IMEI" rules="required" />
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(TWIG Beacon)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(device_type)">
            <BaseInput v-model.trim="alarm_phone_number" type="text" field_name="Telematiknummer" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(Avigilon Alta Compact Dome 5MP)').test(device_type)">
            <BaseInput v-model.trim="serial_number" type="text" field_name="Serienummer" rules="required" />
          </div>

          <div class="w-full md:w-1/3 px-2" v-if="new RegExp('(Avigilon Alta Compact Dome 5MP)').test(device_type)">
            <BaseInput v-model.trim="ava_flex.tag_serial_number" type="text" field_name="Tag serienummer" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="new RegExp('(Avigilon Alta Compact Dome 5MP)').test(device_type)">
            <BaseInput v-model.trim="ava_flex.webhook" type="text" field_name="Webhook" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="new RegExp('(Avigilon Alta Compact Dome 5MP)').test(device_type)">
            <BaseInput v-model.trim="ava_flex.camera_token" type="text" field_name="Token" rules="required" />
          </div>

          <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(device_type)">
            <BaseSelect v-model="operator" :options="sim_operators" field_name="Operatör/Simkort" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>

        <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
          <div class="w-full md:w-1/3 px-2">
            <BaseSelect v-model="alarm_central" :options="[{ name: 'Ingen', _id: '-' }, ...alarm_centrals]" nameSelector="name" optionSelector="_id" field_name="Larmcentral" rules="required" @change="check_alarm_number()" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="show_alarm_number">
            <BaseInput v-model.trim="alarm_number" type="text" rules="required" field_name="Larmnummer" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseSwitch v-model="man_down" field_name="Man-down?" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)"></div>

        <div class="flex flex-wrap mb-5">
          <div class="w-full md:w-1/3 px-2">
            <label class="inline-flex items-center mt-3">
              <input v-model="cash_agreement" :value="cash_agreement" type="checkbox" class="form-checkbox h-4 w-4" @change="switchAgreement()" />
              <span class="ml-2 text-sm font-sans font-semibold">Kontantavtal</span>
            </label>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2" v-if="cash_agreement">
            <BaseInput v-model="agreement_number" type="text" field_name="Avtalsnummer" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="cash_agreement">
            <BaseInput v-model="agreement_length" type="text" field_name="Avtalslängd" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="!cash_agreement">
            <label class="text-gray-700 text-sm font-bold pb-2">Avtalsnummer<b class="required-star">*</b></label>
            <ValidationProvider name="Avtalsnummer" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <input-mask class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 disabled:opacity-50" type="text" v-model="agreement_number" mask="SE9999-9999"></input-mask>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="!cash_agreement">
            <BaseInput v-model.trim="agreement_length" type="text" field_name="Avtalslängd" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseSelect v-model="seller" :options="sellers" nameSelector="name" optionSelector="_id" field_name="Säljare" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>

        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="delivery_company.name" type="text" field_name="Företag" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="delivery_company.phone" type="text" field_name="Telefon" readonly disabled />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="delivery_company.email" type="text" field_name="Email" readonly disabled />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>

        <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
          <div class="w-full md:w-1/3 px-2">
            <BaseSelect v-model="selected_alarm_sort" :options="['Grupplarm', 'Personligt']" field_name="Personligt/Grupplarm" rules="required" @change="check_personal_alarm()" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseSelect v-model="risk_profile" :options="['Hotlarm', 'Nödlarm', 'Överfallslarm (ej återkallningsbart)']" rules="required" field_name="Riskprofil" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="user" type="number" rules="required" field_name="Antal användare" />
          </div>
        </div>

        <div class="flex flex-wrap" v-if="show_personal_alarm && !new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="personal_alarm.reporting" type="text" field_name="Signalement" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="personal_alarm.risk_profile" type="text" field_name="Riskprofil" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="personal_alarm.name" type="text" field_name="Namn" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)"></div>

        <div class="flex flex-wrap">
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="alarm_name" type="text" rules="required" field_name="Namn på Produkt" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
            <BaseInput v-model="from_address.city" type="text" rules="required" field_name="Utgår från adress" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
            <BaseInput v-model="alarm_unit_name" type="text" rules="required" field_name="Verksamhetsnamn" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
            <BaseTextarea rows="5" v-model="business_description" field_name="Arbetsuppgift" rules="required" />
          </div>
          <div class="w-full md:w-1/3 px-2" v-if="device_type == 'SRT330' || device_type == 'SRT430'">
            <BaseTextarea rows="5" v-model="comment" field_name="Kommentar" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>

        <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
          <div class="w-full text-xs italic px-2 mb-2">
            Lösenord – används för behörighet vid provlarm och motbud
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="alarm_password" type="text" rules="required" field_name="Lösenord" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="personal_password" type="text" field_name="Personligt lösenord" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)"></div>

        <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
          <div class="w-full text-xs italic px-2 mb-2">
            Extern åtgörare (tex vaktbolag, förutsätter avtal med åtgöraren)
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="external_contractor.name" type="text" field_name="Namn" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="external_contractor.phone" type="text" field_name="Kontaktnummer" />
          </div>
          <div class="w-full md:w-1/3 px-2">
            <BaseInput v-model="external_contractor.object_id" type="text" field_name="Objekt-Id Åtgörare (om tillgängligt)" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)"></div>

        <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
          <div class="w-full text-sm font-bold px-2 mb-2">
            Kontaktpersoner
          </div>
          <div class="w-full text-xs italic px-2 mb-2">
            <p>Kontaktpersoner består av de kontakter inom er egen organisation som ni vill ska bli kontaktade vid ett larm. Minst ett nummer måste ha giltighetstid dygnet runt. Lördag innebär normalt alla arbetsfria dagar som ej är en arbetsfri helgdag. Ni kan kombinera namn med funktioner, tex.</p>

            <ol>
              <li>Vaktmästeriet – 031 703 10 00 - Vardag 08-16</li>
              <li>Pelle Palm – 0799 99 99 99 - dygnet runt</li>
            </ol>
          </div>
          <div class="w-full flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
            <div class="w-full md:w-3/12 px-2">
              <BaseInput v-model="contact_persons[0].name" type="text" rules="required" field_name="Namn" placeholder="Namn" />
            </div>
            <div class="w-full md:w-3/12 px-2">
              <BaseInput v-model="contact_persons[0].function" type="text" rules="required" field_name="Funktion" placeholder="Funktion" />
            </div>
            <div class="w-full md:w-3/12 px-2">
              <BaseInput v-model="contact_persons[0].phone" type="text" rules="required" field_name="Nummer" placeholder="Nummer" />
            </div>
            <div class="w-full md:w-1/12 px-2">
              <BaseInput v-model="contact_persons[0].weekday" type="text" field_name="Vardag" placeholder="Vardag" />
            </div>
            <div class="w-full md:w-1/12 px-2">
              <BaseInput v-model="contact_persons[0].saturday" type="text" field_name="Lördag" placeholder="Lördag" />
            </div>
            <div class="w-full md:w-1/12 px-2">
              <BaseInput v-model="contact_persons[0].sunday" type="text" field_name="Sön/Helg" placeholder="Sön/Helg" />
            </div>
          </div>
          <div class="w-full" v-for="(c, i) in contact_persons" :key="i">
            <div class="flex flex-wrap" v-if="i > 0">
              <div class="w-full md:w-3/12 px-2">
                <BaseInput v-model="c.name" type="text" placeholder="Namn" />
              </div>
              <div class="w-full md:w-3/12 px-2">
                <BaseInput v-model="c.function" type="text" placeholder="Funktion" />
              </div>
              <div class="w-full md:w-3/12 px-2">
                <BaseInput v-model="c.phone" type="text" placeholder="Nummer" />
              </div>
              <div class="w-full md:w-1/12 px-2">
                <BaseInput v-model="c.weekday" type="text" placeholder="Vardag" />
              </div>
              <div class="w-full md:w-1/12 px-2">
                <BaseInput v-model="c.saturday" type="text" placeholder="Lördag" />
              </div>
              <div class="w-full md:w-1/12 px-2">
                <BaseInput v-model="c.sunday" type="text" placeholder="Sön/Helg" />
              </div>
            </div>
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)"></div>

        <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(device_type)">
          <div class="w-full px-2 text-lg cursor-pointer" @click="isConfigVisible = !isConfigVisible">
            <div class="clear-both">
              <div class="float-left text-gray-600 font-bold pb-2 font-serif">Tilläggstjänster</div>
              <div class="float-right">
                <BaseIcon v-if="!isConfigVisible" class="" icon="chevron-down" />
                <BaseIcon v-if="isConfigVisible" class="" icon="chevron-up" />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap mt-3" v-if="isConfigVisible && !new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(device_type)">
          <div class="w-full md:w-1/2 px-2 mt-5" v-if="!/(^EMERIT)/.test(device_type)">
            <div class="w-full flex flex-col">
              <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">
                {{ getInternalDevicesAlertType() }} 
                <span v-if="new RegExp('^SRT').test(device_type)">(SRT)</span>
                <span v-if="new RegExp('^TWIG').test(device_type)">(TWIG)</span>
                <span v-if="new RegExp('^4G Box').test(device_type)">(4G Box)</span>
                <span v-if="new RegExp('^AJAX').test(device_type)">(AJAX)</span>
              </div>
              <div class="w-full">
                <treeselect ref="interdeviceTreeSelect" v-model="interdeviceAlertSelectNodes" :options="interdeviceAlerts"
                  :alwaysOpen="true" :appendToBody="false" :defaultExpandLevel="2" :disableBranchNodes="false" :showCount="true" showCountOf="LEAF_DESCENDANTS"
                  openDirection="below" :searchable="true" :maxHeight="500" :limit="2" :clearable="false"
                  :multiple="true" value-consists-of="LEAF_PRIORITY" valueFormat="object" noChildrenText="No Devices" class="treeselect-inline">
                  <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName" class="text-sm">
                    {{ node.label }}
                    <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                  </label>
                </treeselect>
              </div>
              
              <div class="w-full mt-4" v-if="new RegExp('(^TWIG)|(^SRT40)').test(device_type)">
                <BaseTextarea rows="1" v-model="vibration_pushb_template" maxlength="130" field_name="On-Screen Message Push-Button" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_pushb_template += 'Skarpt <LarmNamn> <RF-Tagg>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_pushb_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_pushb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="vibration_pushb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
              <div class="w-full mt-4" v-if="new RegExp('^TWIG|(^SRT40)').test(device_type)">
                <BaseTextarea rows="1" v-model="vibration_mandown_template" maxlength="130" field_name="On-Screen Message Man-Down" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_mandown_template += 'Man-Down <LarmNamn> <RF-Tagg>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_mandown_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_mandown_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="vibration_mandown_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
              <div class="w-full mt-4" v-if="new RegExp('^TWIG|(^SRT40)').test(device_type)">
                <BaseTextarea rows="1" v-model="vibration_callb_template" maxlength="130" field_name="On-Screen Message Call-Button" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_callb_template += 'Internt <LarmNamn> <RF-Tagg>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_callb_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_callb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="vibration_callb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
              <div class="w-full mt-4" v-if="new RegExp('(^SRT40)').test(device_type)">
                <BaseTextarea rows="1" v-model="vibration_callb2_template" maxlength="130" field_name="On-Screen Message Call-Button 2" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_callb2_template += 'Internt <LarmNamn> <RF-Tagg>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_callb2_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="vibration_callb2_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="vibration_callb2_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full md:w-1/3 px-2">
            <BaseSelect v-model="sms_alert" :options="sms_alerts" field_name="SMS" nameSelector="name" optionSelector="id" />
            <div class="w-full mt-3 flex flex-col" v-if="sms_alert != ''">
              <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">Nummer <b class="text-xs italic font-normal font-sans text-secondary-500">(Fullständigt mobilnummer med land/riktnummer)</b></div>
              <div class="w-full flex flex-wrap mt-2" v-for="(n, i) in sms_alert_phone" :key="i">
                <div class="w-11/12">
                  <ValidationProvider :name="'SMS Nummer ' + (i + 1)" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input v-model="n.msisdn" type="text" @keypress="isNumber($event)" maxlength="15" class="w-full bg-gray-100 rounded text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 py-2" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <button class="w-1/12 text-red-500 pl-2" @click.prevent="removeSMSInput(i)"><BaseIcon icon="minus-circle" /></button>
              </div>
              <div class="w-full">
                <button class="btn-blue-outline mt-2" @click.prevent="addSMSInput()">Tillägga <BaseIcon icon="plus" class="ml-1" /></button>
              </div>
              <div class="w-full mt-4" v-if="sms_alert == 'sms_pushb' || sms_alert == 'sms_both'">
                <BaseTextarea rows="2" v-model="sms_pushb_template" maxlength="160" field_name="SMS Push-Button" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_pushb_template += 'Skarpt <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_pushb_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_pushb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_pushb_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="sms_pushb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
              <div class="w-full mt-4" v-if="sms_alert == 'sms_mandown' || sms_alert == 'sms_both'">
                <BaseTextarea rows="2" v-model="sms_mandown_template" maxlength="160" field_name="SMS Man-Down" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_mandown_template += 'Man-Down <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_mandown_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_mandown_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_mandown_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="sms_mandown_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
              <div class="w-full mt-4" v-if="sms_alert == 'sms_callb' || sms_alert == 'sms_both'">
                <BaseTextarea rows="2" v-model="sms_callb_template" maxlength="160" field_name="SMS Call-Button" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_callb_template += 'Internt <LarmNamn> <RF-Tagg> <SecurCloud>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_callb_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_callb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="sms_callb_template += '<SecurCloud>'">SecurCloud<BaseIcon icon="map-marker-alt" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="sms_callb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!/(4G Box)|(AJAX)/.test(device_type)" class="w-full md:w-1/3 px-2 pt-3">
            <BaseSelect v-model="display_alert" :options="display_alerts" field_name="Display SRT 330/430" nameSelector="name" optionSelector="id" />
            <div class="w-full mt-3 flex flex-col" v-if="display_alert != ''">
              <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">Nummer <b class="text-xs italic font-normal font-sans text-secondary-500">(Fullständigt mobilnummer med land/riktnummer)</b></div>
              <div class="w-full flex flex-wrap mt-2" v-for="(n, i) in display_alert_phone" :key="i">
                <div class="w-11/12">
                  <ValidationProvider :name="'Display Nummer ' + (i + 1)" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input v-model="n.msisdn" type="text" @keypress="isNumber($event)" maxlength="15" class="w-full bg-gray-100 rounded text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 py-2" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
                <button class="w-1/12 text-red-500 pl-2" @click.prevent="removeDisplayInput(i)"><BaseIcon icon="minus-circle" /></button>
              </div>
              <div class="w-full">
                <button class="btn-blue-outline mt-2" @click.prevent="addDisplayInput()">Tillägga <BaseIcon icon="plus" class="ml-1" /></button>
              </div>
              <div class="w-full mt-4" v-if="display_alert == 'display_pushb' || display_alert == 'display_both'">
                <BaseTextarea rows="2" v-model="display_pushb_template" maxlength="160" field_name="Display Push-Button" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_pushb_template += 'Skarpt <LarmNamn> <RF-Tagg>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_pushb_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_pushb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="display_pushb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
              <div class="w-full mt-4" v-if="display_alert == 'display_mandown' || display_alert == 'display_both'">
                <BaseTextarea rows="2" v-model="display_mandown_template" maxlength="160" field_name="Display Man-Down" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_mandown_template += 'Man-Down <LarmNamn> <RF-Tagg>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_mandown_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_mandown_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="display_mandown_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
              <div class="w-full mt-4" v-if="display_alert == 'display_callb' || display_alert == 'display_both'">
                <BaseTextarea rows="2" v-model="display_callb_template" maxlength="160" field_name="Display Call-Button" rules="required" />
                <div class="">
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_callb_template += 'Internt <LarmNamn> <RF-Tagg>'">Default</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_callb_template += '<LarmNamn>'">Larmnamn</button>
                  <button class="btn-secondary-outline text-xxs mt-1 mr-1" @click.prevent="display_callb_template += '<RF-Tagg>'">RF-Tagg<BaseIcon icon="wifi" class="ml-1" /></button>
                  <button class="btn-red-outline text-xxs mt-1" @click.prevent="display_callb_template = ''">Clear<BaseIcon icon="backspace" class="ml-1" /></button>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full px-4 py-4 mt-5 bg-gray-50" v-if="!/(4G Box)|(AJAX Hub)/.test(device_type)">
            <label class="inline-flex items-center mt-3"> <input v-model="send_to_alarm_center" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-gray-600 text-sm font-serif font-bold">Skicka larm till larmcentral</span> </label>
            <template v-if="send_to_alarm_center">
              <div class="w-full" v-for="r in reportTypes" :key="r.id">
                <label class="inline-flex items-center mt-3">
                  <input v-model="tunnel_settings" :value="r.id" type="checkbox" class="form-checkbox h-3 w-3" /><span class="ml-2 text-sm font-sans font-semibold">{{ r.name }}</span>
                </label>
              </div>
            </template>
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX (?!Hub))').test(device_type)"></div>

        <div class="flex flex-wrap mt-4" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)|(Anchor Beacon)|(Avigilon Alta Compact Dome 5MP)|(AJAX)').test(device_type)">
          <div class="w-full px-2 text-xs italic" v-if="risk_profile == 'Hotlarm'">
            <h5 class="font-bold mb-1">Primär insats:</h5>
            <p><span class="font-bold">SVARA MED MIKROFON AVSTÄNGD!</span> Polis och Extern åtgörare vid det tillfälle detta finns inlagt i larmplan, bär tillkallas vid bekräftelse av hot eller våld. Larmoperatör bör spela in samtal, och endast kommunicera med individ efter flertalet försök till kontakt eller om man kan bekräfta att personen befinner sig i trygghet.</p>
            <h5 class="font-bold mb-1 mt-2">Åtgärd vid larm:</h5>
            <ol>
              <li><span class="font-bold">Medlyssning:</span> bekräfta typ av larm. Hot, våld, olycka eller fellarm.</li>
              <li><span class="font-bold">Tillkalla:</span> polis & extern åtgörare om man kan bekräfta hot eller våld. Annars vidare till punkt 4.</li>
              <li><span class="font-bold">Vid Nödlarm:</span>kommunicera med personen och tillkalla ambulans. Gå vidare till punkt 5.</li>
              <li>Om punkt 2 tycks otydlig, gå vidare till punkt 5</li>
              <li><span class="font-bold">Kontakta kontaktperson:</span> meddela om skarpt larm. Vid de tillfälle punkt 2 är otydlig fråga om eventuellt fellarm.</li>
              <li><span class="font-bold">Återkallning:</span> av larm är möjlig med hjälp av behörighetskod.</li>
            </ol>
          </div>

          <div class="w-full px-2 text-xs italic" v-if="risk_profile == 'Nödlarm'">
            <h5 class="font-bold mb-1">Primär insats:</h5>
            <p><span class="font-bold">SVARA MED MIKROFON PÅSLAGEN!</span> Vid olycka sker direktkontakt med kontaktperson för att berätta om situation. Vid bekräftad typ av nödlarm kontaktas även ambulans/räddningstjänst. .</p>
            <h5 class="font-bold mb-1 mt-2">Åtgärd vid larm:</h5>
            <ol>
              <li><span class="font-bold">Medlyssning:</span> bekräfta typ av larm. Hot, våld, olycka eller fellarm.</li>
              <li><span class="font-bold">Kommunicera:</span> Tala med person som har larmat för att få en tydlig bild av situationen. Om personen inte svarar återgå till Punkt 1, för att bekräfta typ av larm.</li>
              <li><span class="font-bold">Kontakta kontaktperson:</span> meddela om skarpt larm och bekräfta plats om möjligt.</li>
              <li><span class="font-bold">Återkallning:</span> av larm är möjlig med hjälp av behörighetskod.</li>
            </ol>
          </div>

          <div class="w-full px-2 text-xs italic" v-if="risk_profile == 'Överfallslarm (ej återkallningsbart)'">
            <h5 class="font-bold mb-1">Primär insats:</h5>
            <p><span class="font-bold">SVARA MED MIKROFON AVSTÄNGD!</span> ÖVERFALLSLARM (EJ ÅTERKALLNINGSBART)</p>
            <h5 class="font-bold mb-1 mt-2">Åtgärd vid larm:</h5>
            <ol>
              <li><span class="font-bold">Tillkalla:</span> Polis och extern åtgörare (om extern åtgörare finns) - OBS, PRIO 1 -Larm</li>
              <li><span class="font-bold">Medlyssning:</span> Spela in samtal, förse Polisen med så mycket information som möjligt</li>
              <li><span class="font-bold">Kontakta kontaktperson:</span> meddela om skarpt larm</li>
              <li><span class="font-bold">Återkallning:</span> av larm är EJ möjligt förens polis är på plats</li>
            </ol>
          </div>
        </div>

        <div class="w-full px-2 mt-2 mb-10">
          <div class="clearfix">
            <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createAlarm()">
              Spara
              <BaseIcon icon="arrow-right" class="ml-2" />
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  title() {
    return `Admin - Ny produkt`;
  },
  components: {
    Treeselect,
  },
  data() {
    return {
      customer_id: this.$route.params.customer_id,
      unit_id: this.$route.params.unit_id,
      copy_alarm_id: this.$route.params.alarmId,
      sim_operators: ["Telia", "Telia DCP", "Telenor", "Tele2", "Kundens Simkort"],
      interdeviceAlertsDefault: [
        { level: 'event', id: "all", label: "All (Default)", isDisabled: false, isDefaultExpanded: false, children: null, message: "<Event> <DeviceName> <RF-Tagg>" },
        { level: 'event', id: "push_button", label: "Push Button", isDisabled: false, isDefaultExpanded: false, children: null, message: "Skarpt <DeviceName> <RF-Tagg>" },
        { level: 'event', id: "call_b", label: "Call Button", isDisabled: false, isDefaultExpanded: false, children: null, message: "Internt <DeviceName> <RF-Tagg>" },
        { level: 'event', id: "call_b2", label: "Call Button 2", isDisabled: false, isDefaultExpanded: false, children: null, message: "Internt 2 <DeviceName> <RF-Tagg>" },
        { level: 'event', id: "man_down", label: "Man Down", isDisabled: false, isDefaultExpanded: false, children: null, message: "Man-Down <DeviceName> <RF-Tagg>" },
        { level: 'event', id: "4gbox", label: "4G Box", isDisabled: false, isDefaultExpanded: false, children: null, message: "" },
        { level: "event", id: "ajax_critical", label: "AJAX SOS", isDisabled: false, isDefaultExpanded: false, children: null, message: "" },
      ],
      interdeviceAlerts: [],
      interdeviceAlertSelectNodes: [],
      sms_alerts: [
        { id: "sms_pushb", name: "Push B" },
        { id: "sms_mandown", name: "Man Down" },
        { id: "sms_callb", name: "Call B" },
        { id: "sms_4gbox", name: "4G Box" },
        { id: "sms_ajax_critical", name: "AJAX SOS" },
        { id: "sms_both", name: "Alla" },
      ],
      display_alerts: [
        { id: "display_pushb", name: "Push B" },
        { id: "display_mandown", name: "Man Down" },
        { id: "display_callb", name: "Call B" },
        { id: "display_4gbox", name: "4G Box" },
        { id: "display_ajax_critical", name: "AJAX SOS" },
        { id: "display_both", name: "Alla" },
      ],
      reportTypes: [{ name: "Internlarm", id: "call_b" }],
      unitDevicesTree: [],
      customer: {},
      unit_info: {},
      alarm_centrals: [],
      sellers: [],
      unit_alarms: [],
      orders: [],
      orders_filtered: [],
      // form fields
      ava_flex: {
      tag_serial_number: "",
      webhook: "",
      camera_token: "",
      },
      device_type: "",
      imei_number: "",
      alarm_phone_number: "",
      serial_number: "",
      operator: "",
      alarm_central: "",
      alarm_number: "",
      interdevice_alerts: [],
      vibration_pushb_template: "",
      vibration_mandown_template: "",
      vibration_callb_template: "",
      vibration_callb2_template: "",
      sms_alert: "",
      sms_alert_phone: [],
      sms_pushb_template: "",
      sms_mandown_template: "",
      sms_callb_template: "",
      display_alert: "",
      display_alert_phone: [],
      display_pushb_template: "",
      display_mandown_template: "",
      display_callb_template: "",
      man_down: false,
      cash_agreement: false,
      agreement_number: "",
      agreement_length: "",
      seller: "",
      delivery_company: {
        name: "Securdia AB",
        phone: "08-520 276 42",
        email: "support@securdia.se",
      },
      selected_alarm_sort: "",
      personal_alarm: {
        reporting: "",
        risk_profile: "",
        name: "",
      },
      risk_profile: "",
      user: "1",
      alarm_name: "",
      from_address: {
        city: "",
      },
      alarm_unit_name: "",
      business_description: "",
      alarm_password: "",
      personal_password: "",
      external_contractor: {
        name: "",
        phone: "",
        object_id: "",
      },
      contact_persons: [
        { name: "", function: "", phone: "", weekday: "", saturday: "", sunday: "" },
        { name: "", function: "", phone: "", weekday: "", saturday: "", sunday: "" },
        { name: "", function: "", phone: "", weekday: "", saturday: "", sunday: "" },
      ],
      comment: "",
      gps_active: true,
      gps_log_active: true,
      send_to_alarm_center: false,
      tunnel_settings: [],
      order_id: "",
      // show/hide fields
      show_alarm_number: false,
      show_personal_alarm: false,
      vibrationCheckAllAlarms: false,
      isConfigVisible: false,
      copy_alarm: null,
    };
  },

  computed: {
    devices() {
      let ajaxDevices = ["AJAX Hub 2 Plus", "AJAX Hub 2 (4G)", "AJAX Hub 2 (2G)", "AJAX Hub (4G)", "AJAX Hub (2G)", "AJAX DoorProtect Plus", "AJAX DoorProtect", "AJAX ReX 2", "AJAX ReX", "AJAX MotionProtect Plus", "AJAX MotionProtect", "AJAX CombiProtect", "AJAX MotionCam", "AJAX MotionCam (PhOD)", "AJAX MotionProtect Curtain", "AJAX MotionCam Outdoor", "AJAX MotionCam Outdoor (PhOD)", "AJAX MotionProtect Outdoor", "AJAX DualCurtain Outdoor", "AJAX KeyPad TouchScreen", "AJAX KeyPad Plus", "AJAX KeyPad", "AJAX Button", "AJAX DoubleButton", "AJAX SpaceControl"];
      let personalAlarms = ["SRT306", "SRT306i", "SRT306 G2", "SRT326", "SRT330", "SRT334", "SRT405", "SRT405i", "SRT406", "SRT406i", "SRT430", "SRT278", "SRT341", "TWIG Neo", "TWIG Neo SRD", "TWIG Neo MD", "TWIG Neo SRD MD", "TWIG One", "TWIG One SRD", "TWIG One EX", "TWIG Beacon", "TWIG Beacon EX", "EMERIT WG100", "TELTONIKA FMC003", "4G Box", "Anchor Beacon", "Avigilon Alta Compact Dome 5MP"];

      return [...personalAlarms, ...ajaxDevices];
    },
  },

  methods: {
    getCustomer() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer/${this.customer_id}`)
        .then(async (response) => {
          this.customer = response.data.data;
          if (this.copy_alarm_id) this.fetchAlarm();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUnit() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/units/${this.unit_id}`)
        .then((response) => {
          this.unit_info = response.data.data;
          this.$nextTick().then(() => {
            this.alarm_unit_name = this.unit_info.name;
            // this.from_address.city = `${this.unit_info.address} ${this.unit_info.zip_code} ${this.unit_info.city}`;
            this.alarm_central = this.unit_info.alarm_central;
            this.seller = this.unit_info.seller_id;
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUnitAlarms() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/unit/alarms/${this.unit_id}`)
        .then((response) => {
          this.unit_alarms = response.data.unit_alarms;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    async getAlarmCentrals() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarm_centrals = response.data.data;
          this.check_alarm_number();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getSellersResellers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/seller/reseller`)
        .then((response) => {
          this.sellers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getOrderDocument() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/orders`)
        .then((response) => {
          this.orders = response.data.orders;
          this.orders_filtered = this.orders;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getDevicesTree() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/list/customer/${this.customer_id}/devices`)
        .then((response) => {
          this.unitDevicesTree = response.data.devicesTree;
          this.populateInterDeviceAlertTree();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    check_alarm_number() {
      let a = _.find(this.alarm_centrals, { _id: this.alarm_central });

      if (a && /(Stanley Security)|(Securitas AB)|(Rapid Larmcentral)|(Cubsec Larmcentral)|(Security Assistance Syd AB)/.test(a.name)) this.show_alarm_number = true;
      else {
        this.alarm_number = "";
        this.show_alarm_number = false;
      }
    },

    check_personal_alarm() {
      if (this.selected_alarm_sort == "Personligt") this.show_personal_alarm = true;
      else {
        (this.personal_alarm = {
          reporting: "",
          risk_profile: "",
          name: "",
        }),
          (this.show_personal_alarm = false);
      }
    },

    populateAlarmFields() {
      this.device_type = this.copy_alarm.type;
      this.operator = this.copy_alarm.operator;
      this.alarm_central = this.copy_alarm.alarm_central;
      this.alarm_number = this.copy_alarm.alarm_number;
      this.interdevice_alerts = this.copy_alarm.interdevice_alerts;
      this.vibration_pushb_template = this.copy_alarm.vibration_pushb_template;
      this.vibration_mandown_template = this.copy_alarm.vibration_mandown_template;
      this.vibration_callb_template = this.copy_alarm.vibration_callb_template;
      this.sms_alert = this.copy_alarm.sms_alert;
      this.sms_alert_phone = this.copy_alarm.sms_alert_phone;
      this.sms_pushb_template = this.copy_alarm.sms_pushb_template.trim();
      this.sms_mandown_template = this.copy_alarm.sms_mandown_template.trim();
      this.sms_callb_template = this.copy_alarm.sms_callb_template.trim();
      this.display_alert = this.copy_alarm.display_alert;
      this.display_alert_phone = this.copy_alarm.display_alert_phone;
      this.display_pushb_template = this.copy_alarm.display_pushb_template.trim();
      this.display_mandown_template = this.copy_alarm.display_mandown_template.trim();
      this.display_callb_template = this.copy_alarm.display_callb_template.trim();
      this.man_down = this.copy_alarm.man_down;
      this.cash_agreement = this.copy_alarm.cash_agreement;
      this.agreement_number = this.copy_alarm.agreement_number;
      this.agreement_length = this.copy_alarm.agreement_length;
      this.seller = this.copy_alarm.seller;
      this.delivery_company = this.copy_alarm.delivery_company;
      this.selected_alarm_sort = this.copy_alarm.selected_alarm_sort;
      this.personal_alarm = this.copy_alarm.personal_alarm;
      this.risk_profile = this.copy_alarm.risk_profile;
      this.user = this.copy_alarm.user;
      this.from_address = this.copy_alarm.from_address;
      // this.alarm_unit_name = this.copy_alarm.alarm_unit_name;
      this.business_description = this.copy_alarm.business_description;
      this.alarm_password = this.copy_alarm.alarm_password;
      this.personal_password = this.copy_alarm.personal_password;
      this.external_contractor = this.copy_alarm.external_contractor;
      this.contact_persons = this.copy_alarm.contact_persons;
      this.gps_active = this.copy_alarm.gps_active;
      this.gps_log_active = this.copy_alarm.gps_log_active;
      this.send_to_alarm_center = this.copy_alarm.send_to_alarm_center;
      this.tunnel_settings = this.copy_alarm.tunnel_settings;
      // For camera integration
      this.ava_flex.tag_serial_number = this.copy_alarm.ava_flex.tag_serial_number;
      this.ava_flex.webhook = this.copy_alarm.ava_flex.webhook;
      this.ava_flex.camera_token = this.copy_alarm.ava_flex.camera_token;

      this.check_alarm_number();
      this.check_personal_alarm();
      this.populateInterDeviceAlertTree();
      this.filterOrders();

      this.$refs.formCreateAlarm.reset();
    },

    fetchAlarm() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarms/${this.copy_alarm_id}`)
        .then((response) => {
          this.copy_alarm = response.data.data;
          this.populateAlarmFields();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    switchAgreement() {
      if (this.cash_agreement) {
        this.agreement_number = "Kontant";
        this.agreement_length = "-";
      } else {
        this.agreement_number = "";
        this.agreement_length = "";
      }
    },

    generateInterDeviceAlertSchema() {
      let events = [];

      for (let i = 0; i < this.interdeviceAlertSelectNodes.length; i++) {
        let node = this.interdeviceAlertSelectNodes[i];
        // build desired schema from selected objects
        if(node.level == 'event') {
          let devices = [];

          // process all event children
          for (let j = 0; j < node.children.length; j++) {
            let unit = node.children[j];
            
            // process all unit children
            for (let k = 0; k < unit.children.length; k++) {
              let device = unit.children[k];
              devices.push(device.imei_number);
            }
          }

          // check if event object already exists
          let e = _.findIndex(events, { id: node.id });

          if(e > -1) {
            let event = events[e];
            event.devices = [...event.devices, ...devices];
          }
          else {
            events.push({
              id: node.id,
              devices,
            });
          }
        }
        else if(node.level == 'unit') {
          let devices = [];

          // devices
          for (let j = 0; j < node.children.length; j++) {
            let device = node.children[j];
            devices.push(device.imei_number);
          }

          // check if event object already exists
          let e = _.findIndex(events, { id: node.event });

          if(e > -1) {
            let event = events[e];
            event.devices = [...event.devices, ...devices];
          }
          else {
            events.push({
              id: node.event,
              devices,
            });
          }
        }
        else if(node.level == 'device') {
          let e = _.findIndex(events, { id: node.event });

          if(e > -1) {
            let event = events[e];
            event.devices.push(node.imei_number);
          }
          else {
            events.push({
              id: node.event,
              devices: [node.imei_number],
            });
          }
        }
      }

      return events;
    },

    populateInterDeviceAlertTree() {
      // populate tree
      // generate selected node array
      let selectedNodes = [];
      let interdeviceAlerts = []

      // populate event based on device type
      for (let i = 0; i < this.interdeviceAlertsDefault.length; i++) {
        let event = this.interdeviceAlertsDefault[i];

        if (( (/(^SRT)|(^TWIG)/.test(this.device_type) && /(^4gbox)|(^ajax_critical)/.test(event.id)) || 
          (/(^TWIG)|(^SRT30)|(^SRT326$)|(^SRT278$)/.test(this.device_type) && event.id == "call_b2") ||
          (this.device_type == "4G Box" && event.id != "4gbox") || 
          (/(^AJAX)/.test(this.device_type) && event.id != "ajax_critical") )) continue;         
        else interdeviceAlerts.push(event);
      }
      this.interdeviceAlerts = interdeviceAlerts;

      // populate event childs
      for (let i = 0; i < this.interdeviceAlerts.length; i++) {
        let event = this.interdeviceAlerts[i];
        
        if (i == 0) event.isDefaultExpanded = true;
        else event.isDefaultExpanded = false;
        
        // uniq id to each node
        let unitDevicesTree = _.cloneDeep(this.unitDevicesTree);

        for (let j = 0; j < unitDevicesTree.length; j++) {
          let unit = unitDevicesTree[j];
          unit.event = event.id;
          // rename ids so they be unique across all events/units/devices
          unit.id = `${event.id}_${unit.id}`;

          if (i == 0 && j == 0) unit.isDefaultExpanded = true;
          else unit.isDefaultExpanded = false;

          let children = [];

          for (let k = 0; k < unit.children.length; k++) {
            let device = unit.children[k];
            device.event = event.id;
            device.id = `${event.id}_${unit.id}_${device.id}`;

            if ( (/^SRT/.test(this.device_type) && /^SRT((?!(334)|(330)|(430)).)*$/.test(device.type) ) || 
              (/^TWIG/.test(this.device_type) && /^TWIG((?!(Beacon)|(Anchor Beacon)).)*$/.test(device.type) ) || 
              (/^4G Box/.test(this.device_type) && /(^TWIG((?!Beacon).)*$)/.test(device.type)) || 
              (/^AJAX/.test(this.device_type) && /((^TWIG((?!Beacon).)*$))|(^SRT((?!(334)|(330)|(430)|(326)|(306)|(278)|(341)).))/.test(device.type)) ) {
                // todo: 4gbox add srt40 series devices
                children.push(device);
                
                // populate selected nodes if device match
                let ei = _.findIndex(this.interdevice_alerts, { id: event.id }); 
                if(ei > -1) {
                  let ev = this.interdevice_alerts[ei];
                  if(ev && ev.devices.indexOf(device.imei_number) > -1) selectedNodes.push(device);
                }
            }
          }

          unit.children = children;
        }

        event.children = unitDevicesTree;
      }

      this.interdeviceAlertSelectNodes = selectedNodes;
    },

    getInternalDevicesAlertType() {
      if (/^SRT40/.test(this.device_type)) return "On-Screen Message";
      if (/^SRT/.test(this.device_type)) return "9-vibb";
      else if (/^TWIG/.test(this.device_type)) return "On-Screen Message";
      else if (/^4G Box/.test(this.device_type)) return "On-Screen Message";
      else if (/^AJAX/.test(this.device_type)) return "On-Screen Message";
      else return "N/A";
    },

    addSMSInput() {
      this.sms_alert_phone.push({ msisdn: "" });
    },

    removeSMSInput(i) {
      this.sms_alert_phone.splice(i, 1);
    },

    addDisplayInput() {
      this.display_alert_phone.push({ msisdn: "" });
    },

    removeDisplayInput(i) {
      this.display_alert_phone.splice(i, 1);
    },

    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    filterOrders() {
      this.order_id = "";
      if (this.device_type != "") this.orders_filtered = _.filter(this.orders, { type: this.device_type });
      else this.orders_filtered = this.orders;

      this.populateInterDeviceAlertTree();
    },

    populateFieldFromOrder() {
      let order = _.find(this.orders, { _id: this.order_id });
      if (order) {
        this.device_type = order.type;
        this.serial_number = order.modelnumber;
        this.imei_number = order.imei_number;
      } else {
        this.serial_number = "";
        this.imei_number = "";
      }
    },

    createAlarm() {
      this.$refs.formCreateAlarm.validate().then((success) => {
        if (!success) {
          return;
        }

        if (this.device_type == "SRT334" || this.device_type == "SRT330" || this.device_type == "TWIG Beacon" || this.device_type == "Anchor Beacon" || this.device_type == "Anchor Beacon" || this.device_type == "Avigilon Alta Compact Dome 5MP") {
          this.gps_active = false;
          this.gps_log_active = false;
        }

        let interdevice_alerts = this.generateInterDeviceAlertSchema();

        // use serial number for imei, incase imei is optional
        let imeiNumber = this.imei_number;
        if(/^AJAX/.test(this.device_type)) imeiNumber = this.serial_number;

        let data = {
          type: this.device_type,
          imei_number: imeiNumber,
          phone: this.alarm_phone_number,
          modelnumber: this.serial_number,
          operator: this.operator,
          alarm_central: this.alarm_central,
          alarm_number: this.alarm_number,
          interdevice_alerts,
          vibration_pushb_template: this.vibration_pushb_template,
          vibration_mandown_template: this.vibration_mandown_template,
          vibration_callb_template: this.vibration_callb_template,
          vibration_callb2_template: this.vibration_callb2_template,
          sms_alert: this.sms_alert,
          sms_alert_phone: this.sms_alert_phone,
          sms_pushb_template: this.sms_pushb_template,
          sms_mandown_template: this.sms_mandown_template,
          sms_callb_template: this.sms_callb_template,
          display_alert: this.display_alert,
          display_alert_phone: this.display_alert_phone,
          display_pushb_template: this.display_pushb_template,
          display_mandown_template: this.display_mandown_template,
          display_callb_template: this.display_callb_template,
          man_down: this.man_down,
          cash_agreement: this.cash_agreement,
          agreement_number: this.agreement_number,
          agreement_length: this.agreement_length,
          seller: this.seller,
          delivery_company: this.delivery_company,
          selected_alarm_sort: this.selected_alarm_sort,
          personal_alarm: this.personal_alarm,
          risk_profile: this.risk_profile,
          user: this.user,
          unit_name: this.alarm_name,
          from_address: this.from_address,
          alarm_unit_name: this.alarm_unit_name,
          business_description: this.business_description,
          alarm_password: this.alarm_password,
          personal_password: this.personal_password,
          external_contractor: this.external_contractor,
          contact_persons: this.contact_persons,
          parent_id: this.unit_info.unit_id,
          comment: this.comment,
          gps_active: this.gps_active,
          gps_log_active: this.gps_log_active,
          send_to_alarm_center: this.send_to_alarm_center,
          tunnel_settings: this.tunnel_settings,
          order_id: this.order_id,
          ava_flex: {
            tag_serial_number: this.ava_flex.tag_serial_number,
            webhook: this.ava_flex.webhook,
            camera_token: this.ava_flex.camera_token,
          },

        };

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/alarms/`, data)
          .then(() => {
            //eslint-disable-line
            this.$router.push({ name: "alarms", params: { type: "customer", customer_id: this.customer_id, unit_id: this.unit_id, id: "-" } });
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },
  },

  created() {
    this.getAlarmCentrals();
    this.getSellersResellers();
    this.getCustomer();
    this.getUnit();
    this.getOrderDocument();
    this.getUnitAlarms();
    this.getDevicesTree();

    this.setPageTitle(`Ny produkt`, "create_alarm_admin");
  },
};
</script>
